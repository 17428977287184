<template>
  <div class="enterprise-settings">
    <el-dialog title="抖音设置" :visible.sync="dialogFormVisible" :before-close="back">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="clientKey" prop="client_key">
          <el-input v-model="ruleForm.client_key"></el-input>
        </el-form-item>
        <el-form-item label="clientSecret" prop="client_secrett">
          <el-input v-model="ruleForm.client_secret"></el-input>
        </el-form-item>
        <el-form-item label="回调地址" prop="redirect_uri">
          <el-input v-model="ruleForm.redirect_uri"></el-input>
        </el-form-item>
        <el-form-item>
          <!-- <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >-->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getDouyinBackendGetConfig,
  postDouyinBackendUpdateConfig
} from "@/service/douyin";
import { Message } from "element-ui";
export default {
  components: {},
  props: ["show"],
  data() {
    return {
      dialogFormVisible: false,
      ruleForm: {
        client_key: "",
        client_secret: "",
        redirect_uri: ""
      },
      rules: {
        client_key: [
          { required: true, message: "请输入clientKey", trigger: "blur" }
        ],
        client_secret: [
          { required: true, message: "请输入clientSecret", trigger: "blur" }
        ],
        redirect_uri: [
          { required: true, message: "请输入回调地址", trigger: "blur" }
        ]
      }
    };
  },
  computed: {},
  created() {
    this.getDouyinBackendGetConfig();
  },
  mounted() {},
  watch: {
    show(val) {
      this.dialogFormVisible = val;
    }
  },
  methods: {
    getDouyinBackendGetConfig() {
      getDouyinBackendGetConfig().then(res => {
        if (res) {
          this.ruleForm = res
          }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          postDouyinBackendUpdateConfig({ ...this.ruleForm })
            .then(res => {
              this.dialogFormVisible = false;
              this.$emit("visableDouyinShow", false);
              Message.success("添加成功");
            })
            .catch(function(error) {
              // 处理 getJSON 和 前一个回调函数运行时发生的错误
              console.log("发生错误！", error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    back() {
      this.dialogFormVisible = false;
      this.$emit("visableDouyinShow", false);
    }
  }
};
</script>

<style lang="less">
@import url("./index.less");
</style>
